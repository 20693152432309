<template>
  <div>
    <h3 ref="intro">
      Decimal Number
    </h3>
    <p>
      One can think of a decimal number as a number that is obtained by the addition of a whole number with
      the decimal fractions of other whole numbers. <br><br>
      So what is a decimal fraction? <br><br>
      Decimal fraction is a special fraction where the denominator is a power of 10, like 10, 100, 1000, etc.
      Example of decimal fractions are 1/10, 1/100, 1/1000, etc. <br><br>
      For example, 4.25 is a decimal number and it is obtained by adding 4 with 1/10th of 2 and 1/100th of 5. In other words,
      $$4.25 = 4 + 2/10 + 5/100$$
    </p>
    <h3 ref="formal">
      Decimal Places
    </h3>
    <p>
      In a decimal number, the placement of a number after the decimal point is called its decimal place.<br><br>
      Decimal place of a number determines the decimal fraction of that number. <br><br>
      The place right after the decimal point is called a Tenth. That is to say &mdash; the number in the Tenth place
      must be divided by 10.
      For example, in 4.5, 5 is in the Tenth place.
      Being in tenth place, 5 must be divided by 10 and added to 4 to obtain 4.5
      <br> <br>
      The second place after the decimal point is called a Hundreth. For example, in 1.23, 3 is in the Hundreth place.
    </p>
    <h3 ref="playgraph">
      MagicGraph | Decimal Places in a Number
    </h3>
    <p>
      Through this MagicGraph, students will learn about the coefficients of a polynomial: what are they and how to find them. So, let's get started.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a number that contains numbers upto 3 decimal places
      The goal of this MagicGraph is to help you identify the decimal place of each number (after the decimal point).
      Tap on each number after the decimal point to find its decimal place.
    </p>
    <h5> To Explore:</h5>
    <p>
      Each time you tap on the shuffle button, the MagicGraph generates a new number.
      Tap on each number after the decimal point to find its decimal place.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'DecimalPlaces',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Decimal Places';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Terms of a Polynomial', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Coefficients of a Polynomial', img:'/assets/number-2.svg', action: () => this.goto('formal')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Decimal Places',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
