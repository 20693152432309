import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeRightText,
//placePoint(board, positionX, positionY, size, cols1, cols2)
//placeDash(board, Pt1, Pt2, width, cols)
    placeArc,
    placeLogo,
    placeErase,
    placeShuffle,
    placeTest
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-1, 30, 21, 8],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd1.suspendUpdate();

brd1.options.layer['image'] =14;
//Variables
placeLogo(brd1);
var a = 1;
var b = 2;
var c = 3;
var d = 4;
var arr = [a, b, c, d];
var j=1;
const cols = ['black', 'red', 'blue', 'green'];
//Title and subtitle
placeTitle(brd1, 'Building a Decimal Number', '');
placeMiddleText(brd1, 10, 27.5, 'Dollars, Dimes and Cents');
///////////////////////////////////////////////////
var i=0; var j=0; var k=0;
///////////////////////////////////////////////////////
var dollarPlus = placeImage(brd1, '/assets/plus.svg', 0, 16.5, 1, 0);
var dollarMinus = placeImage(brd1, '/assets/minuss.svg', 1.25, 16.5, 1., 0);
////////////////////////////////////////////////
var dimePlus = placeImage(brd1, '/assets/plus.svg', 0, 14.5, 1, 0);
var dimeMinus = placeImage(brd1, '/assets/minuss.svg', 1.25, 14.5, 1., 0);
///////////////////////////////////////
var centPlus = placeImage(brd1, '/assets/plus.svg', 0, 12.5, 1, 0);
var centMinus = placeImage(brd1, '/assets/minuss.svg', 1.25, 12.5, 1., 0);
/////////////////////////////
 brd1.create('text', [9.5, 25.75, function(){return  i + '.'  }],{fixed: true, color:'red', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
 brd1.create('text', [10.5, 25.75, function(){return  '' + j + '' }],{fixed: true, color:'blue', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
 brd1.create('text', [11.5, 25.75, function(){return ' '+ k }],{fixed: true, color:'green', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
////////////
var txt1 = brd1.create('text', [9.3, 23, 'Ones'],{display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'red', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}},);
var txt2 = brd1.create('text', [10.3, 21.5, 'Tenth'],{display:'internal',highlight:false,fixed: true, anchorX: 'right', anchorY: 'middle', color:'blue', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}},);
var txt3 = brd1.create('text', [11.3, 20, 'Hundredth'],{display:'internal',highlight:false, fixed: true, anchorX: 'right', anchorY: 'middle', color:'green', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/800.)}},);

var dash1 = placeLine(brd1, [9.5, 23], [9.5, 25], 2, 'red');
var dash2 = placeLine(brd1, [10.5, 21.5], [10.5, 25], 2, 'blue');
var dash3 = placeLine(brd1, [11.5, 20], [11.5, 25], 2, 'green');
////////////////////////////////////////////////
dollarPlus.on('down',function(){
      i=i+1;
  });
dollarMinus.on('down',function(){
      i=i-1;
    });
//////////////////////////////////
dimePlus.on('down',function(){
      j=j+1;
  });
dimeMinus.on('down',function(){
        j=j-1;
    });
////////////////////////////////////////
centPlus.on('down',function(){
      k=k+1;
  });
centMinus.on('down',function(){
      k=k-1;
    });
//////////////////////////////////////////////////////
//var num = brd1.create('text', [10, 26, function(){return  (i+0.1*j+0.01*k).toFixed(2)}],
//{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
////////////////////////////////////////////////////////////////////
var ans1 = brd1.create('text', [2.5, 17, 'Dollars'],{display:'internal',highlight:false,fixed: true, anchorX: 'left', anchorY: 'middle', color:'red', cssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var ans2 = brd1.create('text', [2.5, 15, 'Dimes'],{display:'internal',highlight:false,fixed: true, anchorX: 'left', anchorY: 'middle', color:'blue', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
var ans3 = brd1.create('text', [2.5, 13, 'Cents'],{display:'internal',highlight:false, fixed: true, anchorX: 'left', anchorY: 'middle', color:'green', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(28*brd1.canvasWidth/800.)}},);
////////////////////////////////////////////////////////////////
var dollars = [];
for(let n=0;n<=9;n++)
{
  dollars[n] = placeImage(brd1, '/assets/OneDollar.svg', 5+2*n, 16, 2, 0);
  dollars[n].setAttribute({visible:false});
}
dollars[0].setAttribute({visible:function(){if(i>0){return true}else{return false}}});
dollars[1].setAttribute({visible:function(){if(i>1){return true}else{return false}}});
dollars[2].setAttribute({visible:function(){if(i>2){return true}else{return false}}});
dollars[3].setAttribute({visible:function(){if(i>3){return true}else{return false}}});
dollars[4].setAttribute({visible:function(){if(i>4){return true}else{return false}}});
dollars[5].setAttribute({visible:function(){if(i>5){return true}else{return false}}});
dollars[6].setAttribute({visible:function(){if(i>6){return true}else{return false}}});
dollars[7].setAttribute({visible:function(){if(i>7){return true}else{return false}}});
dollars[8].setAttribute({visible:function(){if(i>8){return true}else{return false}}});
dollars[9].setAttribute({visible:function(){if(i>9){return true}else{return false}}});
///////////////////////////////////////////////////////////////
var dimes =[];
for(let m=0;m<=9;m++)
{
  dimes[m]=placeImage(brd1, '/assets/OneDime.svg', 5.25+2*m, 14.25, 1.5, 0);
  dimes[m].setAttribute({visible:false});
}
dimes[0].setAttribute({visible:function(){if(j>0){return true}else{return false}}});
dimes[1].setAttribute({visible:function(){if(j>1){return true}else{return false}}});
dimes[2].setAttribute({visible:function(){if(j>2){return true}else{return false}}});
dimes[3].setAttribute({visible:function(){if(j>3){return true}else{return false}}});
dimes[4].setAttribute({visible:function(){if(j>4){return true}else{return false}}});
dimes[5].setAttribute({visible:function(){if(j>5){return true}else{return false}}});
dimes[6].setAttribute({visible:function(){if(j>6){return true}else{return false}}});
dimes[7].setAttribute({visible:function(){if(j>7){return true}else{return false}}});
dimes[8].setAttribute({visible:function(){if(j>8){return true}else{return false}}});
dimes[9].setAttribute({visible:function(){if(j>9){return true}else{return false}}});
//////////////////////////////////////////////////////////////
var cents =[];
for(let l=0;l<=9;l++)
{
  cents[l]=placeImage(brd1, '/assets/OneCent.svg', 5.5+2*l, 12.5, 1., 0);
  cents[l].setAttribute({visible:false});
}
cents[0].setAttribute({visible:function(){if(k>0){return true}else{return false}}});
cents[1].setAttribute({visible:function(){if(k>1){return true}else{return false}}});
cents[2].setAttribute({visible:function(){if(k>2){return true}else{return false}}});
cents[3].setAttribute({visible:function(){if(k>3){return true}else{return false}}});
cents[4].setAttribute({visible:function(){if(k>4){return true}else{return false}}});
cents[5].setAttribute({visible:function(){if(k>5){return true}else{return false}}});
cents[6].setAttribute({visible:function(){if(k>6){return true}else{return false}}});
cents[7].setAttribute({visible:function(){if(k>7){return true}else{return false}}});
cents[8].setAttribute({visible:function(){if(k>8){return true}else{return false}}});
cents[9].setAttribute({visible:function(){if(k>9){return true}else{return false}}});
//////////////////////////////////////////////////////
//brd1.create('image', ['/assets/oneR.svg', [9, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/twoR.svg', [11.5, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//brd1.create('image', ['/assets/threeR.svg', [14, 17], [0.75 , 0.75]], {visible: true, fixed: true, highlight:false,});
//var polynomial1 = brd1.create('text', [8.2, 20, function(){return  a + '.'  }],{fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
//var polynomial2 = brd1.create('text', [9.5, 20, function(){return  '' + b + '' }],{fixed: true, color:'red', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
//var polynomial3 = brd1.create('text', [10.5, 20, function(){return ' '+ c }],{fixed: true, color:'blue', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
//var polynomial4 = brd1.create('text', [11.5, 20, function(){return ' '+ d }],{fixed: true, color:'green', anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd1.canvasWidth/500.)}},);
/* polynomial1.on('down',function(){
      txt0.setAttribute({visible: true});
      dash0.setAttribute({visible: true});
  });
polynomial2.on('down',function(){
      txt1.setAttribute({visible: true});
      dash1.setAttribute({visible: true});
  });
polynomial3.on('down',function(){
      txt2.setAttribute({visible:true});
      dash2.setAttribute({visible: true});
    });
polynomial4.on('down',function(){
      txt3.setAttribute({visible:true});
      dash3.setAttribute({visible: true});
    }); */
//////////////////////////////////////////////////////
var analytics = placeErase(brd1);
//placeImage(brd1, '/assets/shuffle.svg', 8.75, 5, 1.5, 0);
analytics.setLabel('Tap to Erase and Restart')
analytics.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
analytics.on('over', function () {this.label.setAttribute({visible:true});});
analytics.on('out', function () {this.label.setAttribute({visible:false});});

var erase = placeShuffle(brd1);
erase.setLabel('Tap to Test Your Answer')
erase.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
erase.on('over', function () {this.label.setAttribute({visible:true});});
erase.on('out', function () {this.label.setAttribute({visible:false});});
//Erase Function
var check = function(){
  if(i==j)
  {rght.setAttribute({visible:true});}
  else {
   wrng[0].setAttribute({visible:true});
  }
}
erase.on('down',function(){
  //txt0.setAttribute({visible: true});
  //dash0.setAttribute({visible: true});
  rght.setAttribute({visible:false});
  wrng[0].setAttribute({visible:false});
  check();
  txt1.setAttribute({visible: true});
  dash1.setAttribute({visible: true});
  txt2.setAttribute({visible: true});
  dash2.setAttribute({visible: true});
  txt3.setAttribute({visible: true});
  dash3.setAttribute({visible: true});
});
//Shuffle function
analytics.on('down',function(){
  i =0;
  j=0;
  k=0;
});
brd1.unsuspendUpdate();
}
}
export default Boxes;
